import { registerApplication, start } from 'single-spa';

import { LoadConfig } from 'bf-lib-browser/common';

registerApplication(
  'root', // Name of this single-spa application
  async () => {
    await LoadConfig();
    return import('./root.app');
  }, // Our loading function
  () => true, // Our activity function
);
start();
